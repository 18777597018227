$input-padding: .25rem 0 .1rem;
$input-fontsize: $fontsize-default;
$input-radius: $radio-small;
$input-backgroud: none transparent;
$input-border: 0;
$input-color: $color-white;
$input-weight: 500;
$input-background-focus: transparent;
$input-border-focus: 0;
$input-height: 1.5rem;
$input-border-warning: 1px solid $color-warning;
$input-color-placeholder:  color('gray', 'lighten-3');
$input-error-color-warning: $color-warning;
$input-label-color: $color-white;
$input-label-color-focus: color('gray', 'lighten-4');
$input-font-family: $primary-font;

.input-component {
  background: color('gray', 'lighten-1');
  padding: .5rem 1rem;
  border-radius: $radio-small;
  label{
    color: $input-label-color;
    font-weight: 300;
  }
  input, textarea {
    padding: $input-padding;
    font-size: $input-fontsize;
    font-family: $input-font-family;
    border-radius: $input-radius;
    background: $input-backgroud;
    border: $input-border;
    color: $input-color;
    font-weight: $input-weight;
    width: calc(100% - 1rem);
    line-height: $input-height;
    &:focus {
      background: $input-background-focus;
      border: $input-border-focus;
    }
    &.warning {
      border: $input-border-warning;
    }
    &::placeholder {
      color: $input-color-placeholder;
      font-weight: 300;
    }
  }
  .warning-content, .error-message {
    color: $input-error-color-warning;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  &.disabled {
    opacity: .6;
  }
  &:focus-within {
    label {
      color: $input-label-color-focus;
    }
  }
}
