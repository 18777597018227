$fiilter-height : 72px;

@include keyframes(fadeInFromNone){
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.vehicles-catalog{
  width: 100%;
  padding-left: $aside-width;
  position: relative;
  min-height: calc(100vh - 110px);
  @media #{$large-aux-and-down} {
    padding-left: $aside-width-small;
  }
  @media #{$medium-and-down} {
    padding-left: 0;
    min-height: calc(100vh - 42px);
  }
  .vehicles-catalog-filter{
    position: absolute;
    width: calc(100% - #{$aside-width});
    top: -#{$fiilter-height};
    left: $aside-width;
    height: $fiilter-height;
    @media #{$large-aux-and-down} {
      width: calc(100% - #{$aside-width-small});
      left: $aside-width-small;
    }
    @media #{$medium-and-down} {
      width: 100%;
      left: 0;
      position: fixed;
      bottom: 0;
      z-index: 9;
      top: initial;
      height: 120px;
    }
    .model-selector{
      cursor: pointer;
      background: none transparent;
      height: 54px;
      border: 2px solid color('gray', 'lighten-2');
      border-radius: 27px;
      padding: 0.25rem 1.5rem!important;
      max-width: 420px;
      @media #{$large-and-down} {
        max-width: 300px;
      }
      @media #{$medium-and-down} {
        height: 40px;
        max-width: 1024px;
      }
      h3{
        color: color('gray', 'lighten-2');
        text-transform: none;
        font-weight: 700;
        font-size: 12px;
        @media #{$medium-and-down} {
          line-height: 14px;
        }
      }
      p{
        line-height: 1;
        color: color('gray', 'lighten-2');
        font-weight: 300;
      }
      .vehicle-list{
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(color('gray', 'lighten-3'), .75);
        z-index: 999;
        margin: 0;
        padding: 0;
        @include flexbox();
        @include align(center);
        @include justify-content(center);
        top: calc(-120vh - 100px);
        &.open-list{
          top: 0
        }
        ul{
          margin: 1rem;
          padding: 1rem;
          background: $color-white;
          min-width: 400px;
          @include flexbox();
          @include flex-direction(column);
          border-radius: 8px;
          @media #{$small-and-down} {
            min-width: 300px;
          }
          li {
            border-bottom: 1px solid color('gray', 'lighten-5');
            &.no-nav{
              border-bottom-width: 0;
              a{
                &:after, &:before{
                  display: none;
                }
              }
            }
            @media #{$medium-and-up} {
              &:hover{
                background: color('gray', 'lighten-6');
              }
            }
            a{
              position: relative;
              padding: .5rem 1rem;
              @media #{$medium-and-up} {
                cursor: pointer;
              }
              &:after{
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                border: 1px solid color('gray', 'lighten-3');
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 16px;
                box-sizing: border-box;
              }
              &.active-category{
                font-weight: 700;
                &:after{
                  border-color: $color-primary;
                }
                &:before{
                  content: '';
                  display: block;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  position: absolute;
                  left: 2px;
                  top: 18px;
                  background: $color-primary;
                }
                .text-gray{
                  color: $color-gray;
                }
              }
            }
          }
        }
      }
    }
    nav{
      @media #{$medium-and-down} {
        @include flexbox();
        @include flex-direction(row);
        @include align(center);
        .model-selector{
          @include flex(1);
        }
      }
    }
    .call-button{
      border-radius: $radio-default;
      margin-left: .5rem;
      padding: .5rem;
      @include flexbox();
      background: color('gray', 'lighten-1');
      @include flex-direction(column);
      @include align(center);
      @include justify-content(center);
      @media #{$medium-and-up} {
        display: none!important;
      }
    }
    .responsive-cta{
      @media #{$medium-and-up} {
        display: none!important;
      }
    }
    &.active-fixed{
      &.open-selector{
        z-index: 300;
      }
      @media #{$medium-and-up} {
        position: fixed;
        top: 42px;
        z-index: 10;
        @include animation(fadeInFromNone .5s);
      }
    }
    .all-vehicles{
      @media #{$medium-and-down} {
        display: none;
      }
    }
  }
  .model-list{
    background: $color-white;
    @media #{$medium-and-down} {
      padding-bottom: 100px;
    }
    .top-list{
      display: block;
      width: 0;
      height: 0;
      position: relative;
      top: -110px;
      @media #{$medium-and-down} {
        top: -40px;
      }
    }
    .main-title-cat{
      @media #{$small-and-down} {
        font-size: 28px;
        line-height: 20px;
        .size-4{
          font-size: 16px;
        }
      }
    }
    .vehicle-item{
      @media #{$large-aux-and-down} {
        padding-left: .5rem!important;
        padding-right: .5rem!important;
      }
      .image-area{
        width: 35%;
        img{
          width: 100%;
          height: auto;
        }
      }
      .feature-list{
        li{
          list-style: none;
          position: relative;
          &:after{
            content: '';
            display: block;
            position: absolute;
            left: -20px;
            top: 5px;
            width: 12px;
            height: 6px;
            border-bottom: 1px solid $color-primary;
            border-left: 1px solid $color-primary;
            @include transform(rotate(-40deg))
          }
        }
      }
      .vehicle-ctas{
        @include justify-content(space-around);
        .rent-cta{
          i{
            &:after, &:before{
              border-color: $color-primary;
            }
          }
        }
      }
    }
  }
}
