body{
  background-position: 450px 0;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../../assets/icons/bg_map.png");
}

.home{
  width: 100%;
  height: calc(100vh - #{$nav-height});
  padding-left: $aside-width;
  @media #{$large-aux-and-down} {
    padding-left: $aside-width-small;
  }
  @media #{$medium-and-down} {
    padding-left: 0;
    position: relative;
  }
  .content-area{
    align-self: flex-start;
    padding-top: 5rem;
  }
  .home-bg{
    .brand{
      width: 250px;
      margin-bottom: 4rem;
    }
    .main-title{
      font-size: 48px;
      line-height: 1;
      @media #{$large-and-down} {
        font-size: 38px;
      }
    }
    width: 100%;
    height: 100%;
    .image-container{
      width: 70%;
      height: auto;
      position: relative;
      margin-left: -120px;
      z-index: 100;
      img{
        width: 100%;
        height: auto;
      }
      @media #{$large-aux-and-down} {
        margin-left: -40px;
      }
      @media #{$medium-and-down} {
        margin-left: 32px;
        padding-bottom: 8rem;
      }
      @media #{$small-and-down} {
        width: 80%;
      }
    }
    .content-area{
      @media #{$medium-and-up} {
        position: absolute;
        width: 25%;
        right: 0;
        top: 0;
      }
      @media #{$medium-and-down} {
        padding: 32px 32px 100px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include align(flex-end);
        .brand-area{
          @include flexbox();
          @include flex-direction(row);
          @include flex(1);
          @include align(flex-start);
        }
        .brand-description{
          @include flexbox();
          @include flex-direction(row);
          @media #{$small-and-down} {
            h2{
              font-size: 24px;
            }
            p{
              font-size: 14px;
              padding-bottom: 6rem!important;
            }
          }
        }
      }
    }
  }
}
