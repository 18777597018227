$header-background: $color-white;
$nav-height: 42px;

body{
  padding-top: $nav-height;
}

.header{
  height: $nav-height;
  width: 100%;
  background: $header-background;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  .header-right{
    @media #{$small-and-down} {
      display: none;
    }
  }
  .contact-text{
    @media #{$small-and-up} {
      .address-contact{
        display: none;
      }
    }
    @media #{$small-and-down} {
      line-height: 14px;
      @include flexbox();
      @include flex-direction(column);
    }
  }
}
