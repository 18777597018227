/*** color ***/
$primary: (
        'base': $color-primary,
);

$secondary: (
        'base': $color-secondary,
);

$gray: (
        'base': $color-gray,
        'lighten-1': #333333,
        'lighten-2': #4D4D4D,
        'lighten-3': #9F9F9F,
        'lighten-4': #9F9F9F,
        'lighten-5': #D6D6D6,
        'lighten-6': #FAFAFA,
);

$success: (
        'darken-1': darken($color-success, 15%),
        'base': $color-success,
        'lighten-1': lighten($color-success, 15%),
);

$warning: (
        'darken-1': darken($color-warning, 15%),
        'base': $color-warning,
        'lighten-1': lighten($color-warning, 15%),
);

$danger: (
        'darken-1': darken($color-danger, 15%),
        'base': $color-danger,
        'lighten-1': lighten($color-danger, 15%),
);

$info: (
        'darken-1': darken($color-info, 15%),
        'base': $color-info,
        'lighten-1': lighten($color-info, 15%),
);

$colors: (
        'primary': $primary,
        'secondary': $secondary,
        'warning': $warning,
        'success': $success,
        'danger': $danger,
        'info': $info,
        'gray': $gray
);

@include useColors(
                (
                        'primary': true,
                        'secondary': true,
                        'gray': true,
                        'warning': true,
                        'success': true,
                        'danger': true,
                        'info': true
                ),
                'bg'
);

@include useColors(
                (
                        'primary': true,
                        'secondary': true,
                        'warning': true,
                        'success': true,
                        'danger': true,
                        'info': true,
                        'gray': true
                ),
                'text'
);

@include useColors(
                (
                        'primary': true,
                        'secondary': true,
                        'warning': true,
                        'success': true,
                        'danger': true,
                        'info': true,
                        'gray': true
                ),
                'border'
);

@include useColors(
                (
                        'primary': true,
                        'secondary': true,
                        'warning': true,
                        'success': true,
                        'danger': true,
                        'info': true,
                        'gray': true
                ),
                'link'
);

@include useColors(
                (
                        'primary': true,
                        'secondary': true,
                        'warning': true,
                        'success': true,
                        'danger': true,
                        'info': true,
                        'gray': true
                ),
                'gradient'
);

@include useColors(
                (
                        'primary': true,
                        'secondary': true,
                        'warning': true,
                        'success': true,
                        'danger': true,
                        'info': true,
                        'gray': true
                ),
                'fill'
);
