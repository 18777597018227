#__next{
  width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  .main-layout{
    position: relative;
    min-height: 100%;
    left: 0;
    @include transition(all .4s)
  }
}
body{
  &.open-date-picker{
    .home{
      .home-bg{
        .image-container{
          z-index: 1;
        }
      }
    }
  }
}