$aside-background: color('gray', 'base');
$aside-width: 465px;
$aside-width-small: 340px;
$aside-cta-height: 100px;

body{
  &:after{
    z-index: -1;
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    background: $aside-background;;
    width: $aside-width;
    @media #{$large-aux-and-down} {
      width: $aside-width-small;
    }
    @media #{$medium-and-down} {
      display: none;
    }
    height: 100%;
  }
}

.aside-form {
  position: fixed;
  top: $nav-height;
  left: 0;
  z-index: 10;
  width: $aside-width;
  height: calc(100% - #{$nav-height});
  display: block;
  &.responsive-open{
    @media #{$medium-and-down} {
      position: fixed;
      z-index: 9999;
      height: 100vh;
      top: 0;
      .aside-content{
        display: block;
        background: $color-gray;
        height: 100%;
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          pointer-events: none;
        }
        &>div{
          z-index: 99;
          position: relative;
        }
      }
    }
  }
  @media #{$large-aux-and-down} {
    width: $aside-width-small;
  }
  @media #{$medium-and-down} {
    width: 100%;
    height: 0;
    position: absolute;
  }
  .aside-content{
    height: calc(100% - #{$aside-cta-height});
    overflow-x: hidden;
    overflow-y: auto;
    @media #{$medium-and-down} {
      display: none;
    }
    .rent-form{
      max-width: 280px;
    }
    .close-button{
      position: absolute;
      right: 0;
      top: 0;
      @media #{$medium-and-up} {
        display: none;
      }
      i{
        font-size: 24px;
        &:after, &:before{
          background-color: $color-white;
        }
      }
    }
  }
  .aside-cta{
    height: $aside-cta-height;
    @media #{$medium-and-down} {
      display: none;
    }
    &:after{
      pointer-events: none;
      content: '';
      position: absolute;
      display: block;
      bottom: $aside-cta-height;
      height: 100px;
      width: 100%;
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,0) 10%, rgba(255,255,255,0.1) 100%);
    }
    .aside-footer-content{
      height: $aside-cta-height;
    }
  }
}
